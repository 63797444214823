<template>
  <div>
    <Modal class="pop-inquiry" footer-hide v-model="showInquiry">
      <div class="top">
        <div class="product">
          {{ inquiry.inquiry.product_name }}
          <span>[{{ inquiry.inquiry.type_desc }}]</span>
        </div>
        <div class="status status-progress" v-if="inquiry.inquiry.status == 1">
          Active
        </div>
        <div class="status status-completed" v-if="inquiry.inquiry.status == 2">
          Completed
        </div>
        <div class="status status-expired" v-if="inquiry.inquiry.status == 3">
          Expired
        </div>
      </div>
      <div class="content">
        <div class="line-item">
          <div class="item">
            <div class="label">
              Quantity
            </div>
            <div class="value" :title="inquiry.inquiry.quantity">
              {{
                inquiry.quote_quantity
                  ? inquiry.quote_quantity
                  : inquiry.inquiry.quantity || "--"
              }}
            </div>
          </div>
          <div class="item">
            <div class="label">
              Specification
            </div>
            <div class="value" :title="inquiry.inquiry.quality_standard">
              {{
                inquiry.quote_quality_standard
                  ? inquiry.quote_quality_standard
                  : inquiry.inquiry.quality_standard || "--"
              }}
            </div>
          </div>

          <div class="item">
            <div class="label">
              Packaging
            </div>
            <div class="value" :title="inquiry.inquiry.packaging">
              {{
                inquiry.quote_packaging
                  ? inquiry.quote_packaging
                  : inquiry.inquiry.packaging || "--"
              }}
            </div>
          </div>
        </div>
        <div class="line-item">
          <div class="item item-c">
            <div class="label">Factory Name</div>
            <div class="value" :title="inquiry.inquiry.factory">
              {{ inquiry.inquiry.factory }}
            </div>
          </div>
          <div class="item">
            <div class="label">
              Lead Time
            </div>
            <div class="value" :title="inquiry.inquiry.lead_time">
              {{
                inquiry.quote_lead_time
                  ? inquiry.quote_lead_time
                  : inquiry.inquiry.lead_time || "--"
              }}
            </div>
          </div>
        </div>
        <div class="line-item">
          <div class="item item-c">
            <div class="label" title="QC Documents/Factory Certificate">
              QC Documents/Factory Certificate
            </div>
            <div class="value search-button">
              <template v-if="inquiry.inquiry.qc.length > 0">
                <a :href="inquiry.inquiry.qc[0].url" target="_blank">
                  <svg
                    width="30"
                    height="30"
                    viewBox="0 0 30 30"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M23.68 13.89C23.1338 9.5875 19.45 6.25 15 6.25C11.555 6.25 8.5625 8.2625 7.19625 11.4363C4.51125 12.24 2.5 14.775 2.5 17.5C2.5 20.8213 5.11125 23.5188 8.385 23.7138V23.75H22.4875V23.7462L22.5 23.75C25.2575 23.75 27.5 21.5075 27.5 18.75C27.4985 17.6294 27.1214 16.5416 26.4289 15.6606C25.7364 14.7796 24.7685 14.1562 23.68 13.89V13.89ZM10 15H13.75V11.25H16.25V15H20L15 21.25L10 15Z"
                      fill="#F57F45"
                    />
                  </svg>
                </a>
              </template>
              <template v-else>--</template>
            </div>
          </div>
          <div class="item">
            <div class="label">COA / Specification</div>
            <div class="value search-button">
              <template v-if="inquiry.inquiry.coa.length > 0">
                <a :href="inquiry.inquiry.coa[0].url" target="_blank">
                  <svg
                    width="30"
                    height="30"
                    viewBox="0 0 30 30"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M23.68 13.89C23.1338 9.5875 19.45 6.25 15 6.25C11.555 6.25 8.5625 8.2625 7.19625 11.4363C4.51125 12.24 2.5 14.775 2.5 17.5C2.5 20.8213 5.11125 23.5188 8.385 23.7138V23.75H22.4875V23.7462L22.5 23.75C25.2575 23.75 27.5 21.5075 27.5 18.75C27.4985 17.6294 27.1214 16.5416 26.4289 15.6606C25.7364 14.7796 24.7685 14.1562 23.68 13.89V13.89ZM10 15H13.75V11.25H16.25V15H20L15 21.25L10 15Z"
                      fill="#F57F45"
                    />
                  </svg>
                </a>
              </template>
              <template v-else>--</template>
            </div>
          </div>
        </div>
        <div class="line-item">
          <div class="item">
            <div class="label">Shelf Life Expiration Date</div>
            <div class="value">
              {{ inquiry.inquiry.product_expire_date | sliceDate }}
            </div>
          </div>
          <div class="item">
            <div class="label">
              Basis(FOB/CIF/CFR/DDP)
            </div>
            <div class="value" :title="inquiry.inquiry.basis">
              {{ inquiry.inquiry.basis || "--" }}
            </div>
          </div>
          <div class="item">
            <div class="label">
              Instock address
            </div>
            <div class="value" :title="inquiry.inquiry.shipping_address">
              {{
                inquiry.quote_shipping_address
                  ? inquiry.quote_shipping_address
                  : inquiry.inquiry.shipping_address || "--"
              }}
            </div>
          </div>
        </div>
        <div class="line-item">
          <div class="item">
            <div class="label">
              Price ($/kg)
            </div>
            <div class="value" :title="inquiry.inquiry.price">
              {{
                inquiry.quote_price
                  ? inquiry.quote_price
                  : inquiry.inquiry.price || "--"
              }}
            </div>
          </div>
          <div class="item">
            <div class="label">Type</div>
            <div class="value" :title="inquiry.inquiry.type_desc">
              {{ inquiry.inquiry.type_desc || "--" }}
            </div>
          </div>
          <div class="item">
            <div class="label">
              Destination Country
            </div>
            <div class="value" :title="inquiry.inquiry.country_desc">
              {{ inquiry.inquiry.country_desc || "--" }}
            </div>
          </div>
        </div>
        <div class="line-item">
          <div class="item item-all">
            <div class="label">Selling Note</div>
            <div class="value value-c" :title="inquiry.note">
              {{ inquiry.inquiry.note || "--" }}
            </div>
          </div>
        </div>
      </div>
      <div class="note-a">
        <div class="note item">
          <div class="input">
            <div class="label">
              Note
            </div>
            <div class="value value-c">
              {{ inquiry.note || "--" }}
            </div>
          </div>
        </div>
        <div class="item annual">
          <div class="input">
            <div class="label">
              Estimated Annual Quantity
            </div>
            <div class="value">
              {{ inquiry.quote_estimated_annual_quantity || "--" }}
            </div>
          </div>
          <div class="input">
            <div class="label">
              Type
            </div>
            <div class="value">
              {{ inquiry.quote_sourcing_type || "--" }}
            </div>
          </div>
          <div class="input"></div>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import api from "@/api";
const { _storeTradeCollection, _country } = api;
import { mapState } from "vuex";

export default {
  props: [],
  computed: {
    ...mapState("user", ["role"])
  },
  components: {},
  data() {
    return {
      inquiryForm: {
        price: "",
        note: "",
        id: "",
        quote_estimated_annual_quantity: "",
        quote_sourcing_type: ""
      },
      showInquiry: false,
      loading: false,
      showInfo: false,
      infoParam: {
        company_name: "",
        user: "",
        email: "",
        company_id: 0,
        phone: "",
        website: ""
      },
      btnLoading: false,
      // inquiry: this.inquiry,
      // inquiry: this.inquiry
      inquiry: {},
      showHiddenInfo: false,
      editValueData: {
        quote_quantity: false,
        quote_packaging: false,
        quote_basis: false,
        quote_lead_time: false,
        quote_price: false,
        quote_country: false,
        quote_quality_standard: false,
        quote_shipping_address: false
      },
      editValueObj: {
        quote_quantity: "",
        quote_lead_time: "",
        quote_basis: "",
        quote_price: "",
        quote_packaging: "",
        quote_country: 0,
        quote_quality_standard: "",
        quote_shipping_address: ""
      },
      countryList: [
        { id: 1, name: "United States" },
        { id: 2, name: "Canada" },
        { id: 45, name: "China" }
      ]
    };
  },
  mounted() {
    _country().then(response => {
      const { data } = response;
      this.countryList = data;
    });
  },
  watch: {
    inquiry(newData) {
      console.log(newData);
    }
  },
  filters: {
    sliceDate(val) {
      if (val) {
        return val.slice(0, 10);
      } else {
        return null;
      }
    }
  },
  methods: {
    editValue(value) {
      if ("quantity" === value) {
        this.editValueData.quote_quantity = !this.editValueData.quote_quantity;
      }
      if ("packaging" === value) {
        this.editValueData.quote_packaging = !this.editValueData
          .quote_packaging;
      }
      if ("lead_time" === value) {
        this.editValueData.quote_lead_time = !this.editValueData
          .quote_lead_time;
      }
      if ("basis" === value) {
        this.editValueData.quote_basis = !this.editValueData.quote_basis;
      }
      if ("price" === value) {
        this.editValueData.quote_price = !this.editValueData.quote_price;
      }
      if ("country" === value) {
        this.editValueData.quote_country = !this.editValueData.quote_country;
      }
      if ("quality_standard" === value) {
        this.editValueData.quote_quality_standard = !this.editValueData
          .quote_quality_standard;
      }
      if ("shipping_address" === value) {
        this.editValueData.quote_shipping_address = !this.editValueData
          .quote_shipping_address;
      }
    },
    viewCollection() {
      this.$router.push("/trade-collection");
    },
    addCollection() {
      if (this.role === "") {
        this.loading = false;
        this.btnLoading = false;
        this.$Message.error("Please log in first");
        return;
      }
      _storeTradeCollection({
        id: this.inquiry.id
      })
        .then(response => {
          console.log(response);
          // this.$Message.success("success");
          this.$emit("getSourcings");
          this.$refs.isGotoCollection.showGuide = true;
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => {
          this.showInquiry = false;
        });
    },
    getImages(item) {
      this.$refs.quotationImages.showUpload = true;
      this.$refs.quotationImages.companyId = item.company_id;
      this.$refs.quotationImages.companyName = item.company_name;
    },
    viewCompanyQuotation(id, name) {
      const str = Math.random()
        .toString(36)
        .substr(2, 10);
      var data = str + id;
      var hashId = window.btoa(data);
      if ("/trades" === this.$route.path) {
        this.$emit("getAllCompany", [hashId, name]);
      } else {
        this.$router.push(
          "/maxsource?company=" +
            hashId +
            "&search_type=" +
            "2,3,4&hc=n&company_name=" +
            name
        );
      }
    },
    cancel() {
      this.showInquiry = false;
    }
  }
};
</script>
<style lang="less" scoped>
.pop-inquiry {
  padding: 30px;
  .top {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    margin-bottom: 20px;
    .product {
      font-size: 20px;
      font-weight: 600;
      color: #222;
      max-width: 430px;
      word-break: break-all;
      span {
        color: #939393;
        font-size: 16px;
        font-weight: 600;
        margin-left: 5px;
      }
    }
    .status {
      width: 90px;
      height: 30px;
      border-radius: 5px;
      display: flex;
      justify-content: center !important;
      color: #fff;
      font-size: 13px;
      align-items: center;
    }
    .status-progress {
      background: #11828f;
    }
    .status-expired {
      background: #c0399f;
    }
    .status-completed {
      background: #925acf;
    }
  }
  .content {
    .line-item {
      display: flex;
      justify-content: space-between;
      margin-bottom: 15px;
      .item {
        width: 33%;
        .label {
          color: #8a8a8a;
          font-size: 14px;
          padding-bottom: 3px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          display: flex;
          .edit-value {
            margin-left: 10px;
            cursor: pointer;
          }
        }
        .value {
          color: #444;
          font-size: 15px;
          font-weight: 600;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          input {
            border-radius: 8px;
            height: 32px;
            border: 2px solid #dfdfe6;
            outline: none;
            width: 88%;
            padding-left: 10px;
          }
          select {
            border-radius: 8px;
            height: 32px;
            border: 2px solid #dfdfe6;
            outline: none;
            width: 88%;
            padding-left: 10px;
          }
          input:focus {
            border: 2px solid #f57f45;
          }
        }
      }
      .item-c {
        width: 66%;
      }
      .item-all {
        width: 90%;
      }
    }
  }
  .button {
    display: flex;
    justify-content: right;
    margin-top: 20px;
    .cancel {
      color: #989696;
      font-size: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 10px;
    }
    .add {
      color: #f57f45;
      font-size: 18px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 10px;
      border: 1px solid #f57f45;
      border-radius: 20px;
      margin-left: 10px;
      padding-left: 10px;
      padding-right: 10px;
      width: 200px;
      height: 42px;
    }
    .already {
      color: #b5b4b4;
      /*font-size: 18px;*/
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 10px;
      border: 1px solid #b5b4b4;
      border-radius: 20px;
      margin-left: 10px;
      padding-left: 10px;
      padding-right: 10px;
      width: 215px;
      height: 42px;
    }

    .expired-cancel {
      background: #bababa;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 15px;
      color: #fff;
      padding: 5px 10px 5px 10px;
      border-radius: 5px;
    }
    .save {
      background: #f57f45;
      color: #fff;
      /*width: 82px;*/
      height: 42px;
      font-size: 18px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 20px;
      width: 200px;
    }
    .loading-button {
      opacity: 0.8;
      img {
        width: 18px;
      }
    }
  }
}
.input-value {
  margin-bottom: 15px;
  .label {
    color: #8a8a8a;
    font-size: 14px;
    padding-bottom: 3px;
  }
  .input {
    input {
      border: 2px solid #dfdfe6;
      outline: none;
      width: 200px;
      height: 40px;
      border-radius: 12px;
      padding-left: 10px;
      margin-top: 10px;
    }
    input:focus {
      border: 2px solid #f57f45;
    }
  }
  .textarea {
    textarea {
      border: 1px solid #c1c1c1;
      outline: none;
      width: 200px;
      border-radius: 5px;
      padding-left: 10px;
    }
  }
}

.company {
  background: #f2f2f2;
  border-radius: 10px;
  padding: 15px;
  margin-bottom: 30px;
  .top {
    display: flex;
    justify-content: center;
    border-bottom: 1px solid #cccccc;
    margin-top: 5px;
    padding-bottom: 10px;
    .icon {
      position: relative;
      top: 4px;
      right: 5px;
    }
    .name {
      font-size: 18px;
      font-weight: 600;
    }
  }
  .info {
    display: flex;
    justify-content: center;
    .item {
      width: 33%;
      .label {
        font-size: 14px;
        color: #8a8a8a;
        padding-left: 20px;
      }
      .value {
        font-size: 16px;
        color: #000;
        font-weight: 600;
        /*white-space: nowrap;*/
        /*overflow: hidden;*/
        /*text-overflow: ellipsis;*/
        padding-left: 20px;
        word-wrap: break-word;
      }
      .show-value {
        white-space: normal !important;
        word-wrap: break-word;
      }
    }
  }
}
.info {
  background: #ebebeb;
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 15px;
  .info-title {
    color: #000;
    font-size: 16px;
    font-weight: 700;
    margin: 10px 0 10px 0;
  }
  .warning {
    font-size: 14px;
    color: #ff5400;
  }
  .info-content {
    display: flex;
    justify-content: space-between;
    .right .avatar {
      margin: 0 50px 0 0;
      img {
        height: 80px;
        width: 80px;
        border-radius: 10px;
      }
    }
    .item {
      display: flex;
      margin-bottom: 10px;
      .label {
        margin-right: 5px;
        width: 140px;
        font-size: 15px;
        text-align: right;
      }
      .value {
        font-weight: 600;
      }
      .value-user {
        display: flex;
        .avatar {
          margin-right: 10px;
          img {
            width: 20px;
            height: 20px;
          }
        }
      }
      .value-company {
        color: #f57f45;
      }
    }
  }
}
.more-complete {
  display: flex;
  justify-content: right;
  font-size: 11px;
  .more {
    display: flex;
    margin-right: 20px;
    .word {
      display: flex;
      justify-content: center;
      align-items: center;
      color: #f57f45;
      height: 100%;
      font-size: 13px;
    }
    .icon {
      padding-top: 7px;
    }
  }
  .complete {
    display: flex;
    .word {
      display: flex;
      justify-content: center;
      align-items: center;
      color: #f57f45;
      height: 100%;
      font-size: 13px;
    }
    .icon {
      padding-top: 7px;
    }
  }
}
.bottom {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  .left {
    margin-left: 30px;
  }
  .alert {
    display: flex;
    .right {
      width: 350px;
      font-size: 14px;
      height: 56px;

      /* Grays/Casper/CA-700 */

      background: #334155;
      border-radius: 8px;

      /* Inside auto layout */

      flex: none;
      order: 0;
      flex-grow: 1;
      color: #fff;
      display: flex;
      justify-content: left;
      align-items: center;
      padding: 10px;
    }
    .left {
      margin-left: 0;
    }
  }
}
.note {
  margin-bottom: 15px;
  .input .label {
    color: #87898e;
    font-size: 16px;
    margin-bottom: 12px;
  }
  .input .value input {
    border-radius: 12px;
    height: 42px;
    border: 2px solid #dfdfe6;
    outline: none;
    width: 490px;
    padding-left: 10px;
  }
}

.annual {
  display: flex;
  margin-bottom: 20px;
  justify-content: space-between;
  .input .label {
    color: #87898e;
    font-size: 16px;
    margin-bottom: 12px;
  }
  .input .value input {
    border-radius: 12px;
    height: 42px;
    border: 2px solid #dfdfe6;
    outline: none;
    /*width: 490px;*/
    padding-left: 10px;
    width: 240px;
  }
  .input .value select {
    border-radius: 12px;
    height: 42px;
    border: 2px solid #dfdfe6;
    outline: none;
    /*width: 490px;*/
    padding-left: 10px;
    width: 240px;
  }
}
.note-a {
  background: #f5f4f4;
  border-radius: 20px;
  padding: 15px;
}
</style>

<style lang="less">
.pop-inquiry {
  .ivu-modal {
    width: 620px !important;
    .ivu-modal-content {
      padding-left: 20px;
      padding-right: 20px;
    }
  }
}
</style>
