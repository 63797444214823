<template>
  <div>
    <Modal class="pop-quote" footer-hide v-model="showQuote">
      <div class="top">
        <div class="product">Contact Details</div>
      </div>
      <div class="content">
        <div class="line-item">
          <div class="item">
            <div class="label">Company</div>
            <div class="value" :title="infoParam.company.company_name">
              {{ infoParam.company.company_name }}
            </div>
          </div>

          <div class="item">
            <div class="label">Contact Name</div>
            <div class="value" :title="infoParam.delivery_address">
              {{ infoParam.user.first_name }} {{ infoParam.user.last_name }}
            </div>
          </div>
          <div class="item">
            <div class="label">Email</div>
            <div class="value" :title="infoParam.user.email">
              {{ infoParam.user.email }}
            </div>
          </div>
        </div>
      </div>
      <div class="bottom">
        <div class="right">
          <div class="cancel search-button" @click="cancel">Cancel</div>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import api from "@/api";
const { _queryMySourcingQuote } = api;
import { mapState } from "vuex";

export default {
  props: [],
  computed: {
    ...mapState("user", ["role", "company_id"])
  },
  components: {},

  data() {
    return {
      id: null,
      quoteId: null,
      inquiryForm: { price: "", note: "", id: "" },
      showQuote: false,
      loading: false,
      showInfo: false,
      infoParam: {
        company_name: "",
        user: "",
        email: "",
        phone: "",
        website: ""
      },
      btnLoading: false,
      // inquiry: this.inquiry,
      inquiry: {},
      showHiddenInfo: false
    };
  },
  mounted() {
    this.quoteId = this.$route.query.quote;
    this.id = this.$route.params.id;
    _queryMySourcingQuote({ id: this.id, quote: this.quoteId }).then(res => {
      this.infoParam = res.data;
    });
  },
  filters: {},
  methods: {
    getImages(id) {
      this.$refs.quotationImages.showUpload = true;
      this.$refs.quotationImages.companyId = id;
    },
    changeBasis(basis) {
      this.editInquiryForm.basis === basis
        ? (this.editInquiryForm.basis = "")
        : (this.editInquiryForm.basis = basis);
      console.log(this.editInquiryForm.basis);
    },
    cancel() {
      this.showQuote = false;
    }
  }
};
</script>
<style lang="less" scoped>
.pop-quote {
  padding: 30px;
  .top {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    margin-bottom: 20px;
    .product {
      font-size: 20px;
      font-weight: 600;
      color: #222;
    }
    .status {
      width: 90px;
      height: 30px;
      border-radius: 5px;
      display: flex;
      justify-content: center !important;
      color: #fff;
      font-size: 13px;
      align-items: center;
    }
    .status-progress {
      background: #11828f;
    }
    .status-expired {
      background: #c0399f;
    }
    .status-completed {
      background: #925acf;
    }
  }
  .content {
    .line-item {
      display: flex;
      justify-content: space-between;
      margin-bottom: 15px;
      .item {
        width: 33%;
        .label {
          color: #8a8a8a;
          font-size: 14px;
          padding-bottom: 3px;
        }
        .value {
          color: #646464;
          font-size: 15px;
          font-weight: 600;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
  }
  .button {
    display: flex;
    justify-content: right;
    .cancel {
      color: #989696;
      font-size: 16px;
      display: flex;
      justify-content: right;
      align-items: center;
      margin-right: 10px;
    }
    .expired-cancel {
      background: #bababa;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 15px;
      color: #fff;
      padding: 5px 10px 5px 10px;
      border-radius: 5px;
    }
    .save {
      background: #f57f45;
      color: #fff;
      width: 82px;
      height: 35px;
      font-size: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 10px;
    }
    .loading-button {
      opacity: 0.8;
      img {
        width: 18px;
      }
    }
  }
}
.input-value {
  margin-bottom: 15px;
  .label {
    color: #8a8a8a;
    font-size: 14px;
    padding-bottom: 3px;
  }
  .input {
    input {
      border: 2px solid #dfdfe6;
      outline: none;
      width: 200px;
      height: 40px;
      border-radius: 12px;
      padding-left: 10px;
      margin-top: 10px;
    }
    input:focus {
      border: 2px solid #f57f45;
    }
  }
  .textarea {
    textarea {
      border: 1px solid #c1c1c1;
      outline: none;
      width: 200px;
      border-radius: 5px;
      padding-left: 10px;
    }
  }
}

.company {
  background: #f2f2f2;
  border-radius: 10px;
  padding: 15px;
  margin-bottom: 30px;
  .top {
    display: flex;
    justify-content: center;
    border-bottom: 1px solid #cccccc;
    margin-top: 5px;
    padding-bottom: 10px;
    .icon {
      position: relative;
      top: 4px;
      right: 5px;
    }
    .name {
      font-size: 18px;
      font-weight: 600;
    }
  }
  .info {
    display: flex;
    justify-content: center;
    margin-bottom: 15px;
    .item {
      width: 33%;
      .label {
        font-size: 14px;
        color: #8a8a8a;
        padding-left: 20px;
      }
      .value {
        font-size: 16px;
        color: #000;
        font-weight: 600;
        /*white-space: nowrap;*/
        /*overflow: hidden;*/
        /*text-overflow: ellipsis;*/
        padding-left: 20px;
        word-wrap: break-word;
      }
      .show-value {
        white-space: normal !important;
        word-wrap: break-word;
      }
    }
  }
}
.info {
  background: #ebebeb;
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 15px;
  .info-title {
    color: #000;
    font-size: 16px;
    font-weight: 700;
    margin: 10px 0 10px 0;
  }
  .info-content {
    display: flex;
    justify-content: space-between;
    .right .avatar {
      margin: 0 50px 0 0;
      img {
        height: 80px;
        width: 80px;
        border-radius: 10px;
      }
    }
    .item {
      display: flex;
      margin-bottom: 10px;
      .label {
        margin-right: 5px;
        width: 150px;
        font-size: 15px;
        text-align: right;
      }
      .value {
        font-weight: 600;
      }
      .value-user {
        display: flex;
        .avatar {
          margin-right: 10px;
          img {
            width: 20px;
            height: 20px;
          }
        }
      }
    }
  }
}
.more-complete {
  display: flex;
  justify-content: right;
  font-size: 11px;
  .more {
    display: flex;
    margin-right: 20px;
    .word {
      display: flex;
      justify-content: center;
      align-items: center;
      color: #f57f45;
      height: 100%;
      font-size: 13px;
    }
    .icon {
      padding-top: 7px;
    }
  }
  .complete {
    display: flex;
    .word {
      display: flex;
      justify-content: center;
      align-items: center;
      color: #f57f45;
      height: 100%;
      font-size: 13px;
    }
    .icon {
      padding-top: 7px;
    }
  }
}
.bottom {
  display: flex;
  justify-content: right;
  margin-right: 20px;
  .left {
    margin-left: 30px;
  }
  .alert {
    display: flex;
    .right {
      width: 350px;
      font-size: 14px;
      height: 56px;

      /* Grays/Casper/CA-700 */

      background: #334155;
      border-radius: 8px;

      /* Inside auto layout */

      flex: none;
      order: 0;
      flex-grow: 1;
      color: #fff;
      display: flex;
      justify-content: left;
      align-items: center;
      padding: 10px;
    }
    .left {
      margin-left: 0;
    }
  }
}
</style>

<style lang="less">
.pop-inquiry {
  .ivu-modal {
    width: 620px !important;
    .ivu-modal-content {
      padding-left: 20px;
      padding-right: 20px;
    }
  }
}
</style>
