<template>
  <div v-if="mobileWidth">
    <mobile />
  </div>
  <div v-else>
    <pc />
  </div>
</template>

<script>
import mobile from "./my-sourcingso-attendee-mobile-res";
import pc from "./my-sourcingso-attendee-pc-res";
export default {
  computed: {},
  components: {
    mobile,
    pc
  },
  data() {
    return {
      mobileWidth: window.innerWidth < 800
    };
  },
  created() {},
  mounted() {},
  methods: {},
  filters: {}
};
</script>
